export const formatAmount = (input) => {
  // Remove all non-digit characters
  const cleaned = input.replace(/\D/g, '');

  // Handle the case where the input is being cleared
  if (cleaned === '' || input === '$0.0') {
    return '';
  }

  // Convert cleaned input to a number and handle the case for $0.00
  const numberValue = Number(cleaned) / 100;

  // Format the number with commas for every thousand
  const formattedNumber = numberValue.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  return formattedNumber;
};

export const formatPhoneNumber = (input) => {
  // Remove all non-digit characters
  const cleaned = input.replace(/\D/g, '');

  // Apply the phone number format (###) ###-####
  let formattedNumber = '';
  if (cleaned.length > 0) {
    formattedNumber = `(${cleaned.substring(0, 3)}`;
  }
  if (cleaned.length >= 4) {
    formattedNumber += `) ${cleaned.substring(3, 6)}`;
  }
  if (cleaned.length >= 7) {
    formattedNumber += `-${cleaned.substring(6, 14)}`;
  }

  return formattedNumber;
};
