import React from 'react';
const CopyrightText = () => {
  return (
    <p className='text-[#A6ACB7] text-sm whitespace-pre-line text-center mt-6'>
      Tower Title{'\n'}18 Imperial Place, Providence, RI 02903{'\n\n'}
      Copyright © 2024 Tower Title. All Rights Reserved.
    </p>
  );
};

export default CopyrightText;
