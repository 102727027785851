import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import 'antd/dist/reset.css';
import './index.css';

import { Provider } from 'react-redux';
import store from './redux/store';
import logo from './assets/fullLogo.svg';
import GoAroundLoader from './components/loader/goAroundLoader';
import { ToastContainer } from 'react-toastify';
import Loader from './components/loader';
import { registerLicense } from '@syncfusion/ej2-base';
import { AppConfig } from './utils/config';

// Registering Syncfusion license key
registerLicense(AppConfig.syncfusionLicenseKey);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <Suspense
      fallback={
        <div className='w-full h-screen flex flex-col items-center justify-center bg-gray-400'>
          <img className='w-60 h-25' src={logo} alt='Tower Title' />

          <div className='ml-10'>
            <GoAroundLoader height={60} width={60} color={'#2563eb'} />
          </div>
        </div>
      }
    >
      <BrowserRouter>
        <ToastContainer />
        <Loader />
        <App />
      </BrowserRouter>
    </Suspense>
  </Provider>
);
