import React, { useState } from 'react';
import Modal from 'react-modal';
import TextInput from '../../../components/input/TextInput';
import { changePassword } from '../../../utils/api-helper';

import { useDispatch } from 'react-redux';
import { setLoader } from '../../../redux/slices/loader';
import notification from '../../../components/notification';

import SquareCross from '../../../assets/SquareCross.svg';

const ChangePassword = ({ isOpen, toggleModal }) => {
  const dispatch = useDispatch();
  const [passwordData, setPasswordData] = useState({
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  });

  const handleInputChange = (e) => {
    setPasswordData({
      ...passwordData,
      [e.target.name]: e.target.value
    });
  };

  const handleChangePassword = async () => {
    if (!passwordData?.oldPassword) {
      notification('warning', 'Please enter your current password');
      return;
    }

    if (!passwordData?.newPassword) {
      notification('warning', 'Please enter your new password');
      return;
    }

    if (!passwordData?.confirmNewPassword) {
      notification('warning', 'Please confirm your new password');
      return;
    }

    if (
      passwordData?.newPassword?.match(/^(?=.*[\w\d])(?!.*\s).{6,}$/) === null
    ) {
      notification(
        'warning',
        <p className='whitespace-pre-line'>
          Please ensure your password meets the following criteria: {'\n'}
          1. It must be at least 6 characters long. {'\n'}
          2. It cannot contain any spaces.
        </p>
      );
      return;
    }

    if (passwordData?.newPassword !== passwordData?.confirmNewPassword) {
      notification(
        'warning',
        'New password and confirm new password does not match'
      );
      return;
    }

    try {
      dispatch(setLoader(true));
      console.log('passwordData', passwordData);

      const changePasswordResp = await changePassword({
        oldPassword: passwordData?.oldPassword,
        newPassword: passwordData?.newPassword
      });

      if (changePasswordResp?.status === 200) {
        notification('success', 'Password changed successfully');
        setPasswordData({
          oldPassword: '',
          newPassword: '',
          confirmNewPassword: ''
        });
        toggleModal();
      }
    } catch (error) {
      notification(
        'error',
        error?.response?.data?.error ||
          'An error occurred, Unable to change password. Please try again later.'
      );
      console.error(error);
    } finally {
      dispatch(setLoader(false));
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      // h-[75%]%
      className={`relative w-full min-w-0 max-w-[540px] px-4 h-[60%] left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 rounded-md`}
      overlayClassName={`fixed top-0 left-0 right-0 bottom-0 bg-black/80`}
      ariaHideApp={false}
      onRequestClose={toggleModal}
      style={{ overlay: { zIndex: 25 } }}
      shouldCloseOnOverlayClick={true}
    >
      <button onClick={toggleModal} className='absolute right-6 top-2'>
        <img src={SquareCross} />
      </button>

      <div className={`bg-white rounded-lg h-full overflow-hidden`}>
        <div className={`flex flex-col p-6 h-full`}>
          <span className='text-base font-medium pb-6'>Change password</span>

          <div className='flex flex-col h-full gap-y-5'>
            <div className='flex flex-row gap-x-4'>
              <div className='flex flex-col w-full'>
                <span className='text-neutral-500 text-sm pb-1'>
                  Current password
                </span>
                <TextInput
                  name='oldPassword'
                  handleChange={handleInputChange}
                  value={passwordData?.oldPassword}
                  inputStyle={'bg-neutral-50'}
                  placeholder='Enter your current password'
                  type='password'
                />
              </div>
            </div>

            <div className='flex flex-row gap-x-4'>
              <div className='flex flex-col w-full'>
                <span className='text-neutral-500 text-sm pb-1'>
                  New password
                </span>
                <TextInput
                  name='newPassword'
                  handleChange={handleInputChange}
                  value={passwordData?.newPassword}
                  inputStyle={'bg-neutral-50'}
                  placeholder='Enter your password'
                  type='password'
                />
              </div>
            </div>

            <div className='flex flex-row gap-x-4'>
              <div className='flex flex-col w-full'>
                <span className='text-neutral-500 text-sm pb-1'>
                  Confirm new password
                </span>
                <TextInput
                  name='confirmNewPassword'
                  handleChange={handleInputChange}
                  value={passwordData?.confirmNewPassword}
                  inputStyle={'bg-neutral-50'}
                  placeholder='Enter your password'
                  type='password'
                />
              </div>
            </div>
          </div>

          <button
            onClick={handleChangePassword}
            className='bg-primary-500 text-white text-sm font-medium px-3 py-2 rounded-md'
          >
            Change password
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ChangePassword;
