import React, { useState } from 'react';
import Select, { components } from 'react-select';
import CloseIcon from '../../../assets/CloseIcon.svg';

const SearchableSelect = ({
  name = '',
  value,
  placeholder = '',
  options,
  isMulti = false,
  getOptionValue,
  getOptionLabel,
  onItemSelection = () => {},
  onChange,
  inputRef,
  isClearable = false,
  isOptional,
  controlLabel,
  controlStyle = {},
  defaultMenuIsOpen = false,
  isSearchable = true,
  isDisabled = false,
  singleValueStyle = {},
  tabIndex
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const customStyles = {
    control: (base, state) => ({
      ...base,
      border: state.isFocused ? '1px solid #EDEEF0' : '0',
      borderRadius: '8px',
      boxShadow: 'none', // This line disable the blue border
      fontSize: '14px',
      // REMOVE ME
      // border: `1px solid yellow`,
      padding: '4px 0px',
      '&:hover': { backgroundColor: '#F7F7F7' },
      ...controlStyle
    }),

    input: (base) => ({
      ...base,
      margin: 0,
      padding: 0
    }),

    menu: (provided, state) => ({
      ...provided
      // boxShadow: 'none'
    }),

    menuList: (base) => ({
      ...base,
      '::-webkit-scrollbar': {
        width: '8px',
        height: '0px'
      },
      '::-webkit-scrollbar-track': {
        background: '#f1f1f1'
      },
      '::-webkit-scrollbar-thumb': {
        background: '#888'
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: '#555'
      }
    }),

    option: (provided, state) => ({
      ...provided,
      color: 'black',
      fontSize: '14px',
      padding: '6px 12px 6px 12px',
      backgroundColor: state.isSelected ? '#F7F7F7' : 'inherit',
      '&:hover': { backgroundColor: '#F7F7F7' }
    }),

    multiValue: (base) => ({
      ...base,
      backgroundColor: 'white',
      border: `1px solid #EDEEF0`,
      borderRadius: '8px',
      fontSize: '16.6px'
    }),
    multiValueRemove: (base) => ({
      ...base,
      '&:hover': { backgroundColor: '#F7F7F7', color: 'red' }
    }),
    singleValue: (base) => ({
      ...base,
      ...singleValueStyle
    })
  };

  const customComponents = {
    DropdownIndicator: () => null,
    IndicatorSeparator: () => null,
    MultiValueLabel: (props) => {
      return (
        <div onMouseDown={(e) => handlemultivalueclick(e, props)}>
          <components.MultiValueLabel {...props} />
        </div>
      );
    },

    MultiValueRemove: (props) => {
      return (
        <components.MultiValueRemove {...props}>
          <img
            src={CloseIcon}
            className='w-[10px] h-[10px] mr-1 cursor-pointer'
          />
        </components.MultiValueRemove>
      );
    },

    ClearIndicator: (props) => {
      const {
        innerProps: { ref, ...restInnerProps }
      } = props;

      return (
        <div
          {...restInnerProps}
          ref={ref}
          // style={getStyles('clearIndicator', props)}
        >
          <img
            src={CloseIcon}
            className='w-[10px] h-[10px] mr-1 cursor-pointer'
          />
        </div>
      );
    },

    ...(controlLabel && {
      SingleValue: (props) => {
        return controlLabel(props);
      }
    })
  };

  const handlemultivalueclick = (e, props) => {
    e?.stopPropagation();
    e?.preventDefault();

    onItemSelection(props);
  };

  return (
    <div className='flex flex-row items-center'>
      <div className='w-full'>
        <Select
          tabIndex={tabIndex}
          ref={inputRef}
          value={value}
          name={name}
          components={customComponents}
          isClearable={isClearable}
          placeholder={placeholder}
          options={options}
          isMulti={isMulti}
          styles={customStyles}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          onChange={onChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          defaultMenuIsOpen={defaultMenuIsOpen}
          isSearchable={isSearchable}
          isDisabled={isDisabled}
        />
      </div>
      {isFocused && isOptional && (
        <span className='text-xs font-medium text-neutral-500 right-2 ml-2.5'>
          Optional
        </span>
      )}
    </div>
  );
};

export default SearchableSelect;
