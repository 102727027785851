import React from 'react';
const Button = ({
  icon,
  title,
  handleClick,
  buttonStyle = '',
  textStyle = '',
  iconStyle
}) => {
  return (
    <div
      onClick={handleClick}
      className={`flex p-2 rounded bg-secondary-400 cursor-pointer items-center ${buttonStyle}`}
    >
      {icon && (
        <div className={`${iconStyle}`}>
          <img src={icon} />
        </div>
      )}
      <span className={`text-white select-none ${textStyle}`}>{title}</span>
    </div>
  );
};

export default Button;
