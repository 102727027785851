import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import notification from '../../components/notification';
import { setLoader } from '../../redux/slices/loader';
import logo from '../../assets/FullLogoDark.svg';
import TextInput from '../../components/input/TextInput';
import ROUTES from '../../constants/routes';

import CopyrightText from '../../components/CommonUI/CopyWriteText';
import { setPassword } from '../../utils/api-helper';

const SetPassword = () => {
  const dispatch = useDispatch();
  const param = useParams();
  const navigate = useNavigate();

  const [passwordData, setPasswordData] = useState({
    newPassword: '',
    confirmNewPassword: ''
  });

  const handleChangeUserCredentials = (event) => {
    const { name, value } = event?.target;

    setPasswordData((pre) => ({ ...pre, [name]: value }));
  };

  const handleClickResetPassword = async () => {
    if (!passwordData?.newPassword) {
      notification('warning', 'Please enter your new password');
      return;
    }

    if (!passwordData?.confirmNewPassword) {
      notification('warning', 'Please confirm your new password');
      return;
    }

    if (
      passwordData?.newPassword?.match(/^(?=.*[\w\d])(?!.*\s).{6,}$/) === null
    ) {
      notification(
        'warning',
        <p className='whitespace-pre-line'>
          Please ensure your password meets the following criteria: {'\n'}
          1. It must be at least 6 characters long. {'\n'}
          2. It cannot contain any spaces.
        </p>
      );
      return;
    }

    if (passwordData?.newPassword !== passwordData?.confirmNewPassword) {
      notification(
        'warning',
        'New password and confirm new password does not match'
      );
      return;
    }

    try {
      dispatch(setLoader(true));
      const setPasswordData = {
        user: {
          reset_password_token: param?.uniqueID,
          password: passwordData?.newPassword
        }
      };

      const resetPasswordResp = await setPassword(setPasswordData);
      dispatch(setLoader(false));

      if (resetPasswordResp?.status === 204) {
        notification('success', 'Password has been updated successfully!');
        navigate(ROUTES.login);
      }
    } catch (error) {
      dispatch(setLoader(false));
      notification(
        'error',
        error?.response?.data?.error ||
          'Something went wrong, try again!, Unable to set password!'
      );
    }
  };

  return (
    <div className={`w-full h-screen bg-[#fbfbfb]`}>
      <div className='flex flex-col items-center justify-center py-8 px-2 '>
        <img alt='logo' src={logo} className='mb-6' />
        <div className='p-6 max-w-[500px] bg-white shadow-md rounded-md border border-neutral-200'>
          <span className='text-base font-semibold text-neutral-900'>
            Reset your password
          </span>

          <p className='text-sm text-neutral-900'>
            Please enter you new password below.
          </p>

          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
            className='flex flex-col gap-y-5 mt-6'
          >
            <div className='flex flex-col'>
              <span className='text-[#A6ACB7] text-sm mb-2'>New password</span>
              <TextInput
                name='newPassword'
                type='password'
                value={passwordData?.newPassword}
                handleChange={handleChangeUserCredentials}
                placeholder='Enter your new password…'
                inputStyle='!bg-[#D8D8D8]/20'
              />
            </div>

            <div className='flex flex-col'>
              <span className='text-[#A6ACB7] text-sm mb-2'>
                Confirm new password
              </span>
              <TextInput
                name='confirmNewPassword'
                type='password'
                value={passwordData?.confirmNewPassword}
                handleChange={handleChangeUserCredentials}
                placeholder='Enter your new password again…'
                inputStyle='!bg-[#D8D8D8]/20'
              />
            </div>

            <button
              onClick={handleClickResetPassword}
              className='bg-primary-500 text-white font-medium text-sm rounded-md py-2 mt-4'
            >
              Change password
            </button>
          </form>

          <p className='text-[#A6ACB7] text-sm mt-4 '>
            By changing your password, you agree to the Tower Title{' '}
            <button className='underline'>Terms of Service</button> and{' '}
            <button className='underline'>Privacy Policy</button>.
          </p>
        </div>

        <CopyrightText />
      </div>
    </div>
  );
};

export default SetPassword;
