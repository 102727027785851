import React, { useEffect, useRef, useState } from 'react';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import { useDispatch } from 'react-redux';

import plusGray from '../../../assets/plusGray.svg';
import PersonIcon from '../../../assets/PersonIcon.svg';
import ThreeHorizontalDots from '../../../assets/ThreeHorizontalDots.svg';
import CrossIcon from '../../../assets/Cross.svg';
import EditPen from '../../../assets/EditPenDarkGray.svg';
import Delete from '../../../assets/delete.svg';

import scrollbarStyle from '../../../scrollbar.module.css';
import ConfirmationPopOver from '../../../components/ConfirmationPopOver';
import TextInput from '../../../components/input/TextInput';
import AsyncSelectCommon from '../../../components/SearchableSelect/AsyncSelect';
import {
  getTeamsList,
  deleteTeam,
  getMembersList,
  updateTeam,
  createTeam
} from '../../../utils/api-helper';
import notification from '../../../components/notification';

import { setLoader } from '../../../redux/slices/loader';

const Teams = () => {
  const dispatch = useDispatch();

  const [editMode, setEditMode] = useState(false);
  const [teamsList, setTeamsList] = useState([]);

  const [showOptionDropdown, setShowOptionDropdown] = useState(null);

  const [showConfirmationPopOver, setShowConfirmationPopOver] = useState(null);
  const [confirmationPopOverDetails, setConfirmationPopOverDetails] = useState({
    title: '',
    message: '',
    onConfirm: () => {}
  });

  const [showOptionForMembers, setShowOptionForMembers] = useState(null);

  const [selectedTeamIndex, setSelectedTeamIndex] = useState(null);
  const [selectedTeamData, setSelectedTeamData] = useState({});
  const [isSearchNewMember, setIsSearchNewMember] = useState(false);
  const memberSearchInputRef = useRef(null);

  const handleOptionSelection = (index) => {
    setShowOptionDropdown(index);
  };

  const handleSelectTeam = async (index) => {
    try {
      dispatch(setLoader(true));
      const teamMembersListResp = await getMembersList(
        teamsList[index]?.id,
        '',
        false
      );

      setShowOptionDropdown(null);
      setEditMode(true);
      setSelectedTeamIndex(index);
      setSelectedTeamData({
        name: teamsList[index]?.label,
        members: teamMembersListResp?.data,
        id: teamsList[index]?.id
      });

      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setLoader(false));
      notification(
        'error',
        'Error getting selected team data, please try again'
      );
    }
  };

  const handleClickNewTeam = () => {
    setEditMode(true);
    setSelectedTeamData({
      name: '',
      members: []
    });
    setSelectedTeamIndex(null);
  };

  const handleSelectDeleteFromMenu = (index) => {
    setShowOptionDropdown(null);
    setShowConfirmationPopOver(index);
    setConfirmationPopOverDetails({
      title: 'Remove team?',
      message: 'Are you sure you want to remove this team?',
      onConfirm: () => {
        setShowConfirmationPopOver(null);
        handleDeleteTeam(index);
      }
    });
  };

  const handleCloseEditMode = () => {
    setEditMode(false);
  };

  const handleChangeSelectedTeamData = async (e) => {
    const { name, value } = e.target;

    setSelectedTeamData({
      ...selectedTeamData,
      [name]: value
    });
  };

  const getTeamsListData = async () => {
    try {
      dispatch(setLoader(true));
      const teamResp = await getTeamsList('', false);
      setTeamsList(teamResp?.data);
    } catch (error) {
      console.log('Error in getTeamsListData: ', error);
      notification('error', 'Error in fetching teams list, please try again');
    } finally {
      dispatch(setLoader(false));
    }
  };

  const handleDeleteTeam = async (index) => {
    try {
      dispatch(setLoader(true));
      const selectedTeam = teamsList[index];
      const deleteTeamResp = await deleteTeam(selectedTeam?.id);

      if (deleteTeamResp?.status === 200) {
        const updatedTeamList = teamsList.filter(
          (team) => team?.id !== selectedTeam?.id
        );
        setTeamsList(updatedTeamList);
        notification('success', 'Deleted successfully');
      }
    } catch (error) {
      console.log('Error in handleDeleteTeam: ', error);
      notification(
        'error',
        error?.response?.data?.error ||
          'Error in deleting team, please try again'
      );
    } finally {
      dispatch(setLoader(false));
    }
  };

  // Assignee
  const getAssigneeOptionLabel = (e) => {
    return (
      <div className='flex flex-row gap-x-1.5'>
        <img src={PersonIcon} />
        <span className='text-sm text-black'>{e.label}</span>
      </div>
    );
  };

  const getAssigneeOptions = async (inputValue) => {
    try {
      const membersListResp = await getMembersList(
        '',
        inputValue,
        '',
        selectedTeamData?.members?.map((member) => member?.value)
      );

      //   ?.filter((member) => {
      //     return !selectedTeamData?.members?.some(
      //       (selectedMember) => selectedMember?.value === member?.value
      //     );
      //   });

      return membersListResp?.data;
    } catch (error) {
      console.log('Error in getAssigneeOptions: ', error);
      return [];
    }
  };

  const handleRemoveMember = (selectedMemberIndex) => {
    setShowOptionForMembers(null);
    const updatedMembersList = selectedTeamData?.members.filter(
      (_, index) => index !== selectedMemberIndex
    );

    setSelectedTeamData({
      ...selectedTeamData,
      members: updatedMembersList
    });
  };

  const handleSelectMember = (selectedMember) => {
    setIsSearchNewMember(false);
    setSelectedTeamData({
      ...selectedTeamData,
      members: [...selectedTeamData?.members, selectedMember]
    });
  };

  const handleClickNewMember = () => {
    setIsSearchNewMember(true);

    setTimeout(() => {
      memberSearchInputRef.current.focus();
    }, 100);
  };

  const handleClickUpdateTeam = async () => {
    try {
      dispatch(setLoader(true));

      const updatedData = {
        name: selectedTeamData?.name,
        members: selectedTeamData?.members.map((member) => member?.value)
      };

      const updateTeamResp = await updateTeam(
        selectedTeamData?.id,
        updatedData
      );

      teamsList[selectedTeamIndex] = {
        ...teamsList[selectedTeamIndex],
        label: updateTeamResp?.data?.label,
        membersCount: updateTeamResp?.data?.members?.length
      };
      setTeamsList([...teamsList]);
      setSelectedTeamData({});
      setSelectedTeamIndex(null);
      setEditMode(false);
      notification('success', 'Updated successfully');
    } catch (error) {
      console.log('Error in handleClickUpdateTeam: ', error);
      notification(
        'error',
        error?.response?.data?.error ||
          'Error in updating team, please try again'
      );
    } finally {
      dispatch(setLoader(false));
    }
  };

  const handleCreateTeam = async () => {
    try {
      dispatch(setLoader(true));
      const teamData = {
        name: selectedTeamData?.name,
        members: selectedTeamData?.members.map((member) => member?.value)
      };

      const createTeamResp = await createTeam(teamData);

      teamsList.push({
        id: createTeamResp?.data?.id,
        label: createTeamResp?.data?.label,
        membersCount: createTeamResp?.data?.members?.length
      });

      setTeamsList([...teamsList]);
      setSelectedTeamData({});
      setSelectedTeamIndex(null);
      setEditMode(false);
      notification('success', 'Created successfully');
    } catch (error) {
      console.log('Error in handleCreateTeam: ', error);
      notification(
        'error',
        error?.response?.data?.error ||
          'Error in creating team, please try again'
      );
    } finally {
      dispatch(setLoader(false));
    }
  };

  const initialLoading = () => {
    getTeamsListData();
  };

  useEffect(() => {
    initialLoading();
  }, []);

  return editMode ? (
    <div className='flex flex-col pt-6 h-full'>
      <div className='px-5'>
        <button
          onClick={() => handleCloseEditMode()}
          className='text-sm w-fit text-neutral-700'
        >
          Teams /
        </button>
      </div>

      <div
        className={`flex flex-col gap-y-6 h-full px-5 overflow-y-auto ${scrollbarStyle?.customScroll}`}
      >
        <div className='flex flex-row gap-x-4 lg:w-[60%] mt-5'>
          <div className='flex flex-col w-full'>
            <span className='text-neutral-500 text-sm pb-1'>Name</span>
            <TextInput
              name='name'
              value={selectedTeamData?.name}
              handleChange={handleChangeSelectedTeamData}
              inputStyle={'bg-neutral-50'}
              placeholder='Name'
            />
          </div>
        </div>

        {/* Members */}
        <div>
          <div className='border border-neutral-200 rounded-lg mb-4'>
            <div
              className={`overflow-x-auto ${scrollbarStyle?.customScrollLight}`}
            >
              <div className='flex flex-row w-full py-2 px-3 border-b border-neutral-200'>
                <div className='min-w-[150px] w-[90%]'>
                  <span className='text-neutral-600 text-xs font-medium'>
                    Member
                  </span>
                </div>

                <div className='min-w-[25px] w-[10%]' />
              </div>

              {selectedTeamData?.members?.map((member, index) => {
                return (
                  <div
                    key={index}
                    className='flex flex-row items-center w-full p-3 border-b border-neutral-200'
                  >
                    <div className='flex flex-row gap-x-1.5 min-w-[80px] w-[90%] items-center'>
                      <img src={PersonIcon} />
                      <p className='text-sm overflow-x-hidden text-ellipsis whitespace-nowrap'>
                        {member?.label}
                      </p>
                    </div>

                    <div className='flex flex-row justify-end min-w-[25px] w-[10%]'>
                      <Popover
                        isOpen={showOptionForMembers === index}
                        align={'end'}
                        onClickOutside={() => {
                          setShowOptionForMembers(null);
                        }}
                        reposition={true}
                        positions={['bottom', 'top', 'right', 'left']}
                        content={({ position, childRect, popoverRect }) => (
                          <ArrowContainer
                            position={position}
                            childRect={childRect}
                            popoverRect={popoverRect}
                            arrowColor={'#C7C8C9'}
                            arrowSize={6}
                          >
                            <div
                              className={`flex flex-col bg-white w-[248px] max-h-[350px] rounded overflow-y-auto border border-neutral-200 shadow shadow-gray ${scrollbarStyle?.customScroll}`}
                            >
                              <div>
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleRemoveMember(index);
                                  }}
                                  className='flex flex-row gap-x-1.5 items-center p-3 py-2 text-start hover:bg-neutral-100 w-full'
                                >
                                  <img className='h-4 w-4' src={Delete} />
                                  <span className='text-sm'>Remove member</span>
                                </button>
                              </div>
                            </div>
                          </ArrowContainer>
                        )}
                        containerStyle={{ zIndex: 20 }}
                      >
                        <div>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowOptionForMembers(index);
                            }}
                          >
                            <img src={ThreeHorizontalDots} />
                          </button>
                        </div>
                      </Popover>
                    </div>
                  </div>
                );
              })}
            </div>

            {isSearchNewMember ? (
              <div className='flex flex-row gap-x-1 items-center p-1 py-1.5'>
                <div className='w-full '>
                  <AsyncSelectCommon
                    // setting key as dynamic to re-render the component
                    keyName={selectedTeamData?.members?.length}
                    inputRef={memberSearchInputRef}
                    loadOptions={getAssigneeOptions}
                    getOptionLabel={getAssigneeOptionLabel}
                    placeholder={'Search by name...'}
                    onChange={(e) => {
                      handleSelectMember(e);
                    }}
                    getOptionValue={(e) => e?.value}
                    isMulti={false}
                    isClearable={false}
                    onBlur={() => {
                      setIsSearchNewMember(false);
                    }}
                    defaultMenuIsOpen={true}
                  />
                </div>

                <button onClick={() => setIsSearchNewMember(false)}>
                  <img src={CrossIcon} />
                </button>
              </div>
            ) : (
              <div className='p-4 px-2'>
                <button
                  onClick={handleClickNewMember}
                  className='flex flex-row items-center'
                >
                  <img src={plusGray} />
                  <span className='ml-1 text-sm text-neutral-900/50'>
                    New member
                  </span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className={`flex flex-row gap-x-4 border-t border-neutral-200 px-5 py-4`}
      >
        <button
          onClick={
            selectedTeamData?.id ? handleClickUpdateTeam : handleCreateTeam
          }
          className='bg-primary-500 text-white text-sm font-medium px-3 py-2 rounded-md'
        >
          {selectedTeamData?.id ? 'Update' : 'Create'}
        </button>

        <button
          onClick={handleCloseEditMode}
          className='bg-neutral-200 text-black text-sm font-medium px-3 py-2 rounded-md'
        >
          Cancel
        </button>
      </div>
    </div>
  ) : (
    <>
      <div className='flex flex-col p-6'>
        <div className='mb-4'>
          <span className='text-xl font-semibold'>Teams</span>
        </div>

        <div className='border border-neutral-200 rounded-lg'>
          <div
            className={`overflow-x-auto ${scrollbarStyle?.customScrollLight}`}
          >
            <div className='flex flex-row w-full py-2 px-3 border-b border-neutral-200'>
              <div className='min-w-[80px] w-[75%]'>
                <span className='text-neutral-600 text-xs font-medium'>
                  Name
                </span>
              </div>
              <div className='flex flex-row justify-end min-w-[150px] w-[15%]'>
                <span className='text-neutral-600 text-xs font-medium'>
                  Members
                </span>
              </div>

              <div className='min-w-[25px] w-[10%]' />
            </div>

            {teamsList?.map((teamData, index) => {
              return (
                <div
                  key={index}
                  className='flex flex-row items-center w-full p-3 border-b border-neutral-200'
                  onClick={() => {
                    handleSelectTeam(index);
                  }}
                >
                  <div className='min-w-[80px] w-[75%]'>
                    <p className='text-sm overflow-x-hidden text-ellipsis whitespace-nowrap'>
                      {teamData?.label}
                    </p>
                  </div>

                  <div className='flex flex-row justify-end min-w-[150px] w-[15%]'>
                    <p className='text-sm overflow-x-hidden text-ellipsis whitespace-nowrap'>
                      {teamData?.membersCount
                        ? teamData?.membersCount + ' members'
                        : ''}
                    </p>
                  </div>

                  <div className='flex flex-row justify-end min-w-[25px] w-[10%]'>
                    <Popover
                      isOpen={showOptionDropdown === index}
                      align={'end'}
                      onClickOutside={() => {
                        setShowOptionDropdown(null);
                      }}
                      reposition={true}
                      positions={['bottom', 'top', 'right', 'left']}
                      content={({ position, childRect, popoverRect }) => (
                        <ArrowContainer
                          position={position}
                          childRect={childRect}
                          popoverRect={popoverRect}
                          arrowColor={'#C7C8C9'}
                          arrowSize={6}
                        >
                          <div
                            className={`flex flex-col bg-white w-[248px] max-h-[350px] rounded overflow-y-auto border border-neutral-200 shadow shadow-gray ${scrollbarStyle?.customScroll}`}
                          >
                            <div className='flex flex-col divide-y '>
                              <div>
                                <button
                                  onClick={() => handleSelectTeam(index)}
                                  className='flex flex-row gap-x-1.5 items-center p-3 py-2 text-start hover:bg-neutral-100 w-full'
                                >
                                  <img className='h-4 w-4' src={EditPen} />
                                  <span className='text-sm'>Edit</span>
                                </button>
                              </div>

                              <div>
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleSelectDeleteFromMenu(index);
                                  }}
                                  className='flex flex-row gap-x-1.5 items-center p-3 py-2 text-start hover:bg-neutral-100 w-full'
                                >
                                  <img className='h-4 w-4' src={Delete} />
                                  <span className='text-sm'>Delete</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </ArrowContainer>
                      )}
                      containerStyle={{ zIndex: 20 }}
                    >
                      <div>
                        <ConfirmationPopOver
                          showPopOver={showConfirmationPopOver === index}
                          handleClickPopOver={() => {
                            setShowConfirmationPopOver(null);
                          }}
                          title={confirmationPopOverDetails?.title}
                          message={confirmationPopOverDetails?.message}
                          onConfirm={confirmationPopOverDetails?.onConfirm}
                        >
                          <div />
                        </ConfirmationPopOver>

                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleOptionSelection(index);
                          }}
                        >
                          <img src={ThreeHorizontalDots} />
                        </button>
                      </div>
                    </Popover>
                  </div>
                </div>
              );
            })}
          </div>

          <div className='flex flex-row p-4 px-2'>
            <button
              onClick={() => {
                handleClickNewTeam();
              }}
              className='flex flex-row items-center'
            >
              <img src={plusGray} />
              <span className='ml-1 text-sm text-neutral-900/50'>New team</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Teams;
