import React from 'react';
import styles from './Loder.module.css';
import { useSelector } from 'react-redux';
import GoAroundLoader from './goAroundLoader';

const Loader = () => {
  const { loader } = useSelector((state) => state.loader);

  return (
    <>
      {loader && (
        <div className={styles.preloader}>
          <div className='ml-[75px]'>
            <GoAroundLoader height={150} width={150} color={'#fff'} />
          </div>
        </div>
      )}
    </>
  );
};

export default Loader;
