import React, { useEffect, useContext, createContext } from 'react';
import { useNavigate } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { setLoader } from '../../redux/slices/loader';
import { setUserDetails } from '../../redux/slices/userDetails';
import { resetToInitialState } from '../../redux/slices/dashboard';
import notification from '../notification';
import { getUser, setUserAuthToken } from '../../utils/api-helper';
import ROUTES from '../../constants/routes';

export const AuthContext = createContext();

const Auth = (props) => {
  const { children } = props;
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { userDetails } = useSelector((state) => state.userDetails);

  var pathname = window.location?.pathname;

  const loadUserDetails = async (userToken) => {
    try {
      const sessionUserToken =
        userToken ||
        JSON.parse(window.sessionStorage.getItem(`authorization`))?.token;

      setUserAuthToken(sessionUserToken);

      dispatch(setLoader(true));

      const getUserResp = await getUser();

      let sessionData = { ...getUserResp?.data, token: sessionUserToken };

      dispatch(setUserDetails(sessionData));

      dispatch(setLoader(false));

      if (pathname === ROUTES.login) {
        navigation(`/`);
      }
    } catch (error) {
      dispatch(setLoader(false));
      notification(
        `error`,
        error?.response?.data?.error
          ? error?.response?.data?.error
          : error?.message
      );
    }
  };

  const handleCheckUserSession = async () => {
    const sessionUserID = JSON.parse(
      window.sessionStorage.getItem(`authorization`)
    )?.token;

    // User found at sessionStorage but not at redux
    // Redux is set by finding user id from session
    if (!Object.entries(userDetails)?.length && sessionUserID) {
      loadUserDetails(sessionUserID);
      return;
    }

    //User Not Logged in
    // Excluded Non guarded routes here to avoid login redirection
    const nonGuardedRoutes = [
      ROUTES.login,
      ROUTES.resetPassword,
      ROUTES.setPassword,
      ROUTES.onboarding
    ];

    if (
      !Object.entries(userDetails)?.length &&
      !sessionUserID &&
      !nonGuardedRoutes.some((route) => pathname.includes(route))
    ) {
      return navigation(`${ROUTES.login}/${encodeURIComponent(pathname)}`);
    }

    // User Logged In but still tries to open Login Page
    // Redirects to /
    if (
      Object.entries(userDetails)?.length &&
      sessionUserID &&
      pathname.includes(ROUTES.login)
    ) {
      return navigation(`/`);
    }
  };

  const handleLogout = async () => {
    dispatch(setUserDetails({}));
    window.sessionStorage.setItem('authorization', JSON.stringify({}));
    dispatch(resetToInitialState());
    setUserAuthToken(null);
    navigation(ROUTES.login);
  };

  useEffect(() => {
    handleCheckUserSession();
  }, [pathname]);

  return (
    <AuthContext.Provider
      value={{ handleLogout: handleLogout, loadUserDetails: loadUserDetails }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default Auth;
