import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import notification from '../../../notification';

import ROUTES from '../../../../constants/routes';
import SearchInput from '../../../input/SearchInput';
import Button from '../../../button';

import plusIcon from '../../../../assets/plus.svg';

import scrollbarStyle from '../../../../scrollbar.module.css';
import GoAroundLoader from '../../../loader/goAroundLoader';
import { fetchProfiles, setSearchText } from '../../../../redux/slices/profile';

const ProfileTab = () => {
  const dispatch = useDispatch();
  const {
    profileList,
    loading: isProfileLoading,
    searchText
  } = useSelector((state) => state.profile);

  const navigation = useNavigate();

  //   Search
  const handleChangeSearchText = (e) => {
    dispatch(setSearchText(e?.target?.value));
  };

  const handleSearchProfile = (searchInput) => {
    dispatch(fetchProfiles(searchInput));
  };

  // throttle
  useEffect(() => {
    const throttleTimeout = setTimeout(() => {
      if (searchText !== null) {
        handleSearchProfile(searchText);
      }
    }, 1000);

    return () => {
      clearTimeout(throttleTimeout);
    };
  }, [searchText]);

  useEffect(() => {
    handleSearchProfile();
  }, []);

  return (
    <div className='flex flex-col h-full gap-y-2 '>
      <SearchInput
        placeholder={'Search'}
        handlechangesearch={handleChangeSearchText}
        value={searchText || ''}
      />
      <div className='mt-2'>
        <Button
          icon={plusIcon}
          title={'New Partner'}
          iconStyle={'mr-2'}
          textStyle={`text-sm`}
          buttonStyle={'rounded-lg bg-transparent'}
          handleClick={() => {
            navigation(ROUTES.newProfile);
          }}
        />
      </div>

      <div
        className={`h-full overflow-y-auto w-full ${scrollbarStyle.customScrollLight}`}
      >
        {isProfileLoading ? (
          <div className='flex flex-col justify-center items-center'>
            <GoAroundLoader height={50} width={50} color={'white'} />
          </div>
        ) : profileList?.length ? (
          <div className='flex flex-col gap-y-1'>
            {profileList.map((profile, index) => (
              <button
                onClick={() => {
                  navigation(`${ROUTES.profile}/${profile.id}`);
                }}
                key={index}
                className='flex flex-row justify-between p-2 rounded items-center hover:bg-neutral-200/10 w-full'
              >
                <span className='text-white select-none text-sm truncate'>
                  {profile.name}
                </span>
              </button>
            ))}
          </div>
        ) : (
          <div className='flex justify-center items-center my-4 text-neutral-200'>
            No profiles found
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileTab;
