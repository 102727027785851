import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  topBarHeading: '',
};

export const topBarHeadingSlice = createSlice({
  name: 'topBarHeading',
  initialState,
  reducers: {
    setTopBarHeading: (state, action) => {
      state.topBarHeading = action?.payload;
    },
  },
});

export const { setTopBarHeading } = topBarHeadingSlice?.actions;

export default topBarHeadingSlice?.reducer;
