import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import AccountSettings from './components/AccountSettings';
import ProfileIcon from '../../assets/profile.svg';
import scrollbarStyle from '../../scrollbar.module.css';

import DocumentIcon from '../../assets/DocumentIcon.svg';
import SquareCross from '../../assets/SquareCross.svg';
import People from './components/People';
import Teams from './components/Teams';

import { setLoader } from '../../redux/slices/loader';
import notification from '../../components/notification';
import { getAccessList } from '../../utils/api-helper';

const UserAccount = ({ isOpen, toggleModal }) => {
  const dispatch = useDispatch();

  const [selectedTab, setSelectedTab] = useState('Account');
  const [tabsItemsList, setTabsItemsList] = useState([]);

  const allTabsItemsList = [
    {
      name: 'Account',
      icon: ProfileIcon
    },
    { name: 'Peoples', icon: DocumentIcon },
    {
      name: 'Teams',
      icon: DocumentIcon
    },
    {
      name: 'Projects',
      icon: DocumentIcon
    }
  ];

  const handleTabClick = (tabName) => {
    setSelectedTab(tabName);
  };

  const renderTabContent = () => {
    switch (selectedTab) {
      case 'Account':
        return <AccountSettings toggleModal={toggleModal} />;
      case 'Peoples':
        return <People />;
      case 'Teams':
        return <Teams />;
      case 'Projects':
        return <div className='m-4'>Projects</div>;
    }
  };

  const initLoading = async () => {
    try {
      dispatch(setLoader(true));
      const getAccessListResp = await getAccessList();

      const accessList = getAccessListResp?.data?.accessList;

      const filteredTabsItemsList = allTabsItemsList.filter((tabItem) => {
        if (accessList.includes(tabItem?.name)) {
          return tabItem;
        }
      });
      setTabsItemsList(filteredTabsItemsList);

      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setLoader(false));
      notification('error', 'Error while loading access control settings.');
    }
  };

  useEffect(() => {
    initLoading();
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      // h-[75%]%
      className={`relative w-full min-w-0 sm:min-w-[460px] sm:max-w-[85%] px-4 h-[80%] left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 rounded-md`}
      overlayClassName={`fixed top-0 left-0 right-0 bottom-0 bg-black/80`}
      ariaHideApp={false}
      onRequestClose={toggleModal}
      style={{ overlay: { zIndex: 20 } }}
      shouldCloseOnOverlayClick={true}
    >
      <div className={`bg-white rounded-lg h-full overflow-hidden`}>
        <button onClick={toggleModal} className='absolute right-6 top-2'>
          <img src={SquareCross} />
        </button>

        <div className='flex flex-col h-full md:flex-row '>
          <div
            className={`flex p-1 md:p-2 items-center gap-y-1 bg-neutral-100 gap-x-2  md:flex-col  md:w-[210px] md:h-full  md:pt-6 overflow-x-auto ${scrollbarStyle?.customScrollLight} `}
          >
            {tabsItemsList.map((tabItem, index) => {
              return (
                <button
                  onClick={() => handleTabClick(tabItem?.name)}
                  key={index}
                  className={`flex flex-row gap-x-2 min-w-fit md:w-full items-center p-1.5 rounded ${
                    tabItem?.name === selectedTab
                      ? 'bg-neutral-300'
                      : 'hover:bg-neutral-200'
                  } `}
                >
                  <img src={tabItem?.icon} />
                  <span className='text-sm'>{tabItem?.name}</span>
                </button>
              );
            })}
          </div>

          <div
            className={`w-full h-full overflow-y-auto ${scrollbarStyle?.customScrollLight}`}
          >
            {renderTabContent()}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UserAccount;
