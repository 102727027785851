import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUserDetails } from '../../redux/slices/userDetails';
import notification from '../notification';
import ROUTES from '../../constants/routes';
import { setUserAuthToken } from '../../utils/api-helper';

const useAuthErrorHandler = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleAuthError = (event) => {
      if (
        event?.detail?.response?.status === 401 &&
        event?.detail?.response?.data?.error === 'Session expired'
      ) {
        notification('warning', 'Session expired. Please login again.');
        dispatch(setUserDetails({}));
        window.sessionStorage.setItem('authorization', JSON.stringify({}));
        setUserAuthToken(null);
        navigate(ROUTES.login);
      }
    };

    window.addEventListener('authError', handleAuthError);

    return () => {
      window.removeEventListener('authError', handleAuthError);
    };
  }, []);

  return null;
};

export default useAuthErrorHandler;
