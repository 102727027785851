import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  attchmentTypeList: []
};

export const attchmentTypeListSlice = createSlice({
  name: 'attchmentTypeList',
  initialState,
  reducers: {
    setAttchmentTypeList: (state, action) => {
      state.attchmentTypeList = action?.payload;
    }
  }
});

export const { setAttchmentTypeList } = attchmentTypeListSlice?.actions;

export default attchmentTypeListSlice?.reducer;
