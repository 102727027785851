import React, { useEffect, useState } from 'react';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import { useDispatch } from 'react-redux';

import plusGray from '../../../assets/plusGray.svg';
import PersonIcon from '../../../assets/PersonIcon.svg';
import GroupOfPersonsIcon from '../../../assets/GroupOfPersons.svg';
import ThreeHorizontalDots from '../../../assets/ThreeHorizontalDots.svg';
import TickMark from '../../../assets/TickMark.svg';
import EditPen from '../../../assets/EditPenDarkGray.svg';
import Delete from '../../../assets/delete.svg';

import scrollbarStyle from '../../../scrollbar.module.css';
import ConfirmationPopOver from '../../../components/ConfirmationPopOver';
import TextInput from '../../../components/input/TextInput';
import AsyncSelectCommon from '../../../components/SearchableSelect/AsyncSelect';
import {
  deletePeople,
  getPeoples,
  getRoles,
  getTeamsList,
  sendInvitation,
  updatePeople
} from '../../../utils/api-helper';
import SearchableSelect from '../../../components/SearchableSelect/SearchableSelect';
import notification from '../../../components/notification';

import { setLoader } from '../../../redux/slices/loader';

const regexEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const People = () => {
  const dispatch = useDispatch();

  const [editMode, setEditMode] = useState(false);
  const [roleOptions, setRoleOptions] = useState([]);
  const [peopleList, setPeopleList] = useState([]);

  const [showOptionDropdown, setShowOptionDropdown] = useState(null);

  const [showConfirmationPopOver, setShowConfirmationPopOver] = useState(null);
  const [confirmationPopOverDetails, setConfirmationPopOverDetails] = useState({
    title: '',
    message: '',
    onConfirm: () => {}
  });

  const [selectedPeopleIndex, setSelectedPeopleIndex] = useState(null);
  const [selectedPeopleData, setSelectedPeopleData] = useState({});

  const handleOptioSelection = (index) => {
    setShowOptionDropdown(index);
  };

  const handleSelectPeople = (index) => {
    setShowOptionDropdown(null);
    setEditMode(true);
    setSelectedPeopleIndex(index);
    setSelectedPeopleData(peopleList[index]);
  };

  const handleSelectDeleteFromMenu = (index) => {
    setShowOptionDropdown(null);
    setShowConfirmationPopOver(index);
    setConfirmationPopOverDetails({
      title: 'Remove person',
      message: 'Are you sure you want to remove this person?',
      onConfirm: () => {
        setShowConfirmationPopOver(null);
        handleDeletePeople(index);
      }
    });
  };

  const handleCloseEditMode = () => {
    setEditMode(false);
  };

  const handleChangeSelectedPeopleData = (e) => {
    const { name, value } = e.target;

    if (name === 'firstName' || name === 'lastName' || name === 'email') {
      setSelectedPeopleData({
        ...selectedPeopleData,
        user: {
          ...selectedPeopleData?.user,
          [name]: value
        }
      });

      return;
    }

    setSelectedPeopleData({
      ...selectedPeopleData,
      [name]: value
    });
  };

  // Team
  const getTeamOptionLabel = (e) => {
    return (
      <div className='flex flex-row gap-x-1.5'>
        <img src={GroupOfPersonsIcon} />
        <span className='text-sm text-black'>{e.label}</span>
      </div>
    );
  };

  const getTeamOptions = async (inputValue) => {
    try {
      const teamsResp = await getTeamsList(inputValue);

      return teamsResp?.data;
    } catch (error) {
      console.log('Error in getTeamOptions: ', error);
      return [];
    }
  };

  // Access
  const getAccessOptionLabel = (option) => {
    return (
      <div className='flex flex-col'>
        <span className='text-sm'>{option?.label}</span>
        <span className='text-xs text-neutral-600'>{option?.description}</span>
      </div>
    );
  };

  const formatDataForUpdate = (peopleData) => {
    return {
      id: peopleData?.user?.id,
      firstName: peopleData?.user?.firstName,
      lastName: peopleData?.user?.lastName,
      team: peopleData?.teams?.map((team) => team?.id),
      role: peopleData?.role?.value
    };
  };

  const handleClickAddNewPeople = () => {
    setEditMode(true);
    setSelectedPeopleIndex(null);
    setSelectedPeopleData({
      user: {
        firstName: '',
        lastName: '',
        email: ''
      },
      teams: [],
      role: null
    });
  };

  const handleChangeRole = (peopleIndex, role) => {
    const updatedSelectedPeopleData = formatDataForUpdate({
      ...peopleList[peopleIndex]
    });

    updatedSelectedPeopleData.role = role?.value;

    handleUpdatePeopleData(
      updatedSelectedPeopleData?.id,
      updatedSelectedPeopleData
    );
  };

  const handleUpdatePeopleData = async (peopleId, data) => {
    try {
      dispatch(setLoader(true));
      const updatePeopleResp = await updatePeople(peopleId, data);
      dispatch(setLoader(false));

      if (updatePeopleResp?.status === 200) {
        notification('success', 'People updated successfully');
        const updatedPeopleList = peopleList.map((people) => {
          if (people?.user?.id === updatePeopleResp?.data?.user?.id) {
            return updatePeopleResp?.data;
          }

          return people;
        });

        setPeopleList(updatedPeopleList);
        return true;
      }
    } catch (error) {
      dispatch(setLoader(false));
      console.log('Error in handleUpdatePeopleData: ', error);
      notification(
        'error',
        error?.response?.data?.error ||
          'Error in updating people, please try again'
      );
      return false;
    }
  };

  const getRolesList = async () => {
    try {
      dispatch(setLoader(true));
      const rolesResp = await getRoles();
      setRoleOptions(rolesResp?.data);
    } catch (error) {
      console.log('Error in getRolesList: ', error);
      notification('error', 'Error in fetching roles list, please try again');
    } finally {
      dispatch(setLoader(false));
    }
  };

  const getPeoplesList = async () => {
    try {
      dispatch(setLoader(true));
      const peopleResp = await getPeoples();
      setPeopleList(peopleResp?.data);
    } catch (error) {
      console.log('Error in getPeoplesList: ', error);
      notification('error', 'Error in fetching people list, please try again');
    } finally {
      dispatch(setLoader(false));
    }
  };

  const handleClickUpdatePeople = async () => {
    const updatedSelectedPeopleData = formatDataForUpdate(selectedPeopleData);

    const isUpdated = await handleUpdatePeopleData(
      updatedSelectedPeopleData?.id,
      updatedSelectedPeopleData
    );

    if (isUpdated) {
      setEditMode(false);
    }
  };

  const handleClickInvitePeople = async () => {
    try {
      dispatch(setLoader(true));
      const creationData = {
        firstName: selectedPeopleData?.user?.firstName,
        lastName: selectedPeopleData?.user?.lastName,
        email: selectedPeopleData?.user?.email,
        role: selectedPeopleData?.role?.value,
        team: selectedPeopleData?.teams?.map((team) => team?.id)
      };

      if (!String(creationData?.email?.toLowerCase()).match(regexEmail)) {
        notification('warning', 'Please enter a valid email');
        return;
      }

      if (typeof creationData?.role !== 'number') {
        notification('warning', 'Please select a role');
        return;
      }

      const invitePeopleResp = await sendInvitation(creationData);

      if (invitePeopleResp?.status === 200) {
        if (invitePeopleResp?.data?.isReSend) {
          notification('success', 'Invitation re-sent successfully');
        } else {
          notification('success', 'Invitation sent successfully');
        }

        // setPeopleList([...peopleList, invitePeopleResp?.data]);
        setEditMode(false);
      }

      dispatch(setLoader(true));
    } catch (error) {
      console.log(error);

      notification(
        'error',
        error?.response?.data?.error ||
          'Error in inviting people, please try again'
      );
    } finally {
      dispatch(setLoader(false));
    }
  };

  const handleDeletePeople = async (index) => {
    try {
      dispatch(setLoader(true));
      const selectedPeople = peopleList[index];
      const deletePeopleResp = await deletePeople(selectedPeople?.user?.id);

      if (deletePeopleResp?.status === 200) {
        const updatedPeopleList = peopleList.filter(
          (people) => people?.user?.id !== selectedPeople?.user?.id
        );
        setPeopleList(updatedPeopleList);
        notification('success', 'Deleted successfully');
      }
    } catch (error) {
      console.log('Error in handleDeletePeople: ', error);
      notification(
        'error',
        error?.response?.data?.error ||
          'Error in deleting people, please try again'
      );
    } finally {
      dispatch(setLoader(false));
    }
  };

  const initialLoading = () => {
    getPeoplesList();
    getRolesList();
  };

  useEffect(() => {
    initialLoading();
  }, []);

  return editMode ? (
    <div className='flex flex-col pt-6 h-full'>
      <div className='px-5 mb-2'>
        <button
          onClick={() => handleCloseEditMode()}
          className='text-sm w-fit text-neutral-700'
        >
          People /
        </button>
      </div>

      <div
        className={`flex flex-col gap-y-6 h-full px-5 overflow-y-auto ${scrollbarStyle?.customScroll}`}
      >
        {selectedPeopleData?.user?.id && (
          <div className='text-lg font-medium'>
            <span>
              {peopleList[selectedPeopleIndex]?.user?.firstName +
                ' ' +
                peopleList[selectedPeopleIndex]?.user?.lastName}
            </span>
          </div>
        )}

        <div className='flex flex-row gap-x-4 lg:w-[60%]'>
          <div className='flex flex-col w-full'>
            <span className='text-neutral-500 text-sm pb-1'>First name</span>
            <TextInput
              name='firstName'
              value={selectedPeopleData?.user?.firstName}
              handleChange={handleChangeSelectedPeopleData}
              inputStyle={'bg-neutral-50'}
              placeholder='First name'
              maxLength={80}
            />
          </div>
          <div className='flex flex-col w-full'>
            <span className='text-neutral-500 text-sm pb-1'>Last name</span>
            <TextInput
              name='lastName'
              value={selectedPeopleData?.user?.lastName}
              handleChange={handleChangeSelectedPeopleData}
              inputStyle={'bg-neutral-50'}
              placeholder='Last name'
              maxLength={80}
            />
          </div>
        </div>

        <div className='flex flex-row gap-x-4 lg:w-[60%]'>
          <div className='flex flex-col w-full'>
            <span className='text-neutral-500 text-sm pb-1'>Email</span>
            <TextInput
              name='email'
              value={selectedPeopleData?.user?.email}
              handleChange={handleChangeSelectedPeopleData}
              inputStyle={'bg-neutral-50'}
              placeholder='Email'
              disabled={selectedPeopleData?.user?.id ? true : false}
            />
          </div>
        </div>

        <div className='flex flex-row gap-x-4 lg:w-[60%]'>
          <div className='flex flex-col w-full'>
            <span className='text-neutral-500 text-sm pb-1'>Teams</span>

            <AsyncSelectCommon
              name={'team'}
              loadOptions={getTeamOptions}
              getOptionLabel={getTeamOptionLabel}
              value={selectedPeopleData?.teams}
              placeholder={'No team'}
              onChange={(e) => {
                handleChangeSelectedPeopleData({
                  target: { name: 'teams', value: e }
                });
              }}
              getOptionValue={(e) => e?.id}
              isMulti={true}
              isClearable={true}
              controlStyle={{
                backgroundColor: '#F7F8F9',
                padding: '2px 0px'
              }}
            />
          </div>
        </div>

        <div className='flex flex-row gap-x-4 lg:w-[60%] pb-4'>
          <div className='flex flex-col w-full'>
            <span className='text-neutral-500 text-sm pb-1'>Access</span>

            <SearchableSelect
              name={'role'}
              options={roleOptions}
              placeholder='No access'
              value={selectedPeopleData?.role}
              onChange={(e) => {
                handleChangeSelectedPeopleData({
                  target: { name: 'role', value: e }
                });
              }}
              getOptionValue={(e) => e.label}
              getOptionLabel={getAccessOptionLabel}
              // controlLabel={getAccessControlLabel}
              controlStyle={{
                backgroundColor: '#F7F8F9',
                padding: '2px 0px'
              }}
              // isClearable={true}
            />
          </div>
        </div>
      </div>
      <div
        className={`flex flex-row gap-x-4 border-t border-neutral-200 px-5 py-4`}
      >
        <button
          onClick={
            selectedPeopleData?.user?.id
              ? handleClickUpdatePeople
              : handleClickInvitePeople
          }
          className='bg-primary-500 text-white text-sm font-medium px-3 py-2 rounded-md'
        >
          {selectedPeopleData?.user?.id ? 'Update' : 'Invite'}
        </button>

        <button
          onClick={handleCloseEditMode}
          className='bg-neutral-200 text-black text-sm font-medium px-3 py-2 rounded-md'
        >
          Cancel
        </button>
      </div>
    </div>
  ) : (
    <>
      <div className='flex flex-col p-6'>
        <div className='mb-4'>
          <span className='text-xl font-semibold'>People</span>
        </div>

        <div className='border border-neutral-200 rounded-lg'>
          <div
            className={`overflow-x-auto ${scrollbarStyle?.customScrollLight}`}
          >
            <div className='flex flex-row w-full py-2 px-3 border-b border-neutral-200'>
              <div className='min-w-[150px] w-[20%]'>
                <span className='text-neutral-600 text-xs font-medium'>
                  User
                </span>
              </div>
              <div className='min-w-[85px] w-[30%]'>
                <span className='text-neutral-600 text-xs font-medium'>
                  Email
                </span>
              </div>
              <div className='min-w-[100px] w-[25%]'>
                <span className='text-neutral-600 text-xs font-medium'>
                  Teams
                </span>
              </div>
              <div className='min-w-[150px] w-[25%]'>
                <span className='text-neutral-600 text-xs font-medium'>
                  Access
                </span>
              </div>

              <div className='min-w-[25px] w-[10%]' />
            </div>

            {peopleList?.map((peopleData, index) => {
              return (
                <div
                  key={index}
                  className='flex flex-row items-center w-full p-3 border-b border-neutral-200'
                  onClick={() => {
                    handleSelectPeople(index);
                  }}
                >
                  {/* Name */}
                  <div className='flex flex-row min-w-[150px] w-[20%] gap-x-1 items-center'>
                    <img src={PersonIcon} />
                    <p className='text-sm overflow-x-hidden text-ellipsis whitespace-nowrap'>
                      {peopleData?.user?.firstName +
                        ' ' +
                        peopleData?.user?.lastName}
                    </p>
                  </div>

                  {/* Email */}
                  <div className='min-w-[85px] w-[30%]'>
                    <p className='text-sm overflow-x-hidden text-ellipsis whitespace-nowrap pr-1'>
                      {peopleData?.user?.email}
                    </p>
                  </div>
                  <div className='min-w-[100px] w-[25%]'>
                    {peopleData?.teams?.length ? (
                      <div className='flex flex-row gap-x-1 items-center'>
                        <img src={GroupOfPersonsIcon} />

                        <p className='text-sm overflow-x-hidden text-ellipsis whitespace-nowrap'>
                          {peopleData?.teams[0]?.label}
                        </p>

                        {peopleData?.teams?.length > 1 && (
                          <p className='text-sm'>
                            +{peopleData?.teams?.length - 1}
                          </p>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className='min-w-[150px] w-[25%]'>
                    <p className='text-sm overflow-x-hidden text-ellipsis whitespace-nowrap'>
                      {peopleData?.role?.label}
                    </p>
                  </div>
                  <div className='flex flex-row justify-end min-w-[25px] w-[10%]'>
                    <Popover
                      isOpen={showOptionDropdown === index}
                      align={'end'}
                      onClickOutside={() => {
                        setShowOptionDropdown(null);
                      }}
                      reposition={true}
                      positions={['bottom', 'top', 'right', 'left']}
                      content={({ position, childRect, popoverRect }) => (
                        <ArrowContainer
                          position={position}
                          childRect={childRect}
                          popoverRect={popoverRect}
                          arrowColor={'#C7C8C9'}
                          arrowSize={6}
                        >
                          <div
                            className={`flex flex-col bg-white w-[248px] max-h-[350px] rounded overflow-y-auto border border-neutral-200 shadow shadow-gray ${scrollbarStyle?.customScroll}`}
                          >
                            <div className='px-3 py-2'>
                              <span className='text-neutral-600 text-sm'>
                                Access
                              </span>
                            </div>

                            <div className='flex flex-col divide-y '>
                              <div className='border-neutral-200'>
                                {roleOptions?.map((role, roleindex) => {
                                  return (
                                    <button
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setShowOptionDropdown(null);
                                        handleChangeRole(index, role);
                                      }}
                                      key={roleindex}
                                      className='flex flex-row gap-x-1 justify-between w-full items-center px-3 py-2 text-start hover:bg-neutral-100'
                                    >
                                      <div className='flex flex-col'>
                                        <span className='text-sm'>
                                          {role?.label}
                                        </span>
                                        <span className='text-xs text-neutral-600'>
                                          {role?.description}
                                        </span>
                                      </div>

                                      {peopleData?.role?.value ===
                                        role?.value && (
                                        <div className='min-w-[15px]'>
                                          <img src={TickMark} />
                                        </div>
                                      )}
                                    </button>
                                  );
                                })}
                              </div>

                              <div>
                                <button
                                  onClick={() => handleSelectPeople(index)}
                                  className='flex flex-row gap-x-1.5 items-center p-3 text-start hover:bg-neutral-100 w-full'
                                >
                                  <img className='h-4 w-4' src={EditPen} />
                                  <span className='text-sm'>Edit</span>
                                </button>
                              </div>

                              <div>
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleSelectDeleteFromMenu(index);
                                  }}
                                  className='flex flex-row gap-x-1.5 items-center p-3 text-start hover:bg-neutral-100 w-full'
                                >
                                  <img className='h-4 w-4' src={Delete} />
                                  <span className='text-sm'>Remove</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </ArrowContainer>
                      )}
                      containerStyle={{ zIndex: 20 }}
                    >
                      <div>
                        <ConfirmationPopOver
                          showPopOver={showConfirmationPopOver === index}
                          handleClickPopOver={() => {
                            setShowConfirmationPopOver(null);
                          }}
                          title={confirmationPopOverDetails?.title}
                          message={confirmationPopOverDetails?.message}
                          onConfirm={confirmationPopOverDetails?.onConfirm}
                        >
                          <div />
                        </ConfirmationPopOver>

                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleOptioSelection(index);
                          }}
                        >
                          <img src={ThreeHorizontalDots} />
                        </button>
                      </div>
                    </Popover>
                  </div>
                </div>
              );
            })}
          </div>

          <div className='flex flex-row p-4 px-2'>
            <button
              onClick={handleClickAddNewPeople}
              className='flex flex-row items-center'
            >
              <img src={plusGray} />
              <span className='ml-1 text-sm text-neutral-900/50'>
                New person
              </span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default People;
