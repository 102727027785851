import { configureStore } from '@reduxjs/toolkit';
import loaderReducer from './slices/loader';
import userDetailsReducer from './slices/userDetails';
import topBarHeadingReducer from './slices/topBarHeading';
import orderReducer from './slices/order';
import attchmentTypeListReducer from './slices/attachmentType';
import dashboardReducer from './slices/dashboard';
import profileReducer from './slices/profile';

export default configureStore({
  reducer: {
    loader: loaderReducer,
    userDetails: userDetailsReducer,
    topBarHeading: topBarHeadingReducer,
    order: orderReducer,
    attchmentTypeList: attchmentTypeListReducer,
    dashboard: dashboardReducer,
    profile: profileReducer
  }
});
