import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from '../../../redux/slices/loader';

import { useAuth } from '../../../components/auth';
import TextInput from '../../../components/input/TextInput';
import { formatPhoneNumber } from '../../../utils/valueFormatter';
import ChangePassword from './ChangePassword';
import notification from '../../../components/notification';
import { updateUser } from '../../../utils/api-helper';
import scrollbarStyle from '../../../scrollbar.module.css';

const AccountSettings = ({ toggleModal }) => {
  const dispatch = useDispatch();
  const { loadUserDetails } = useAuth();

  const { userDetails } = useSelector((state) => state.userDetails);
  const [userProfileData, setUserProfileData] = useState({});
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);

  const handleInputChange = (e) => {
    if (e.target.name === 'ph_no') {
      e.target.value = formatPhoneNumber(e.target.value);
    }

    setUserProfileData({
      ...userProfileData,
      [e.target.name]: e.target.value
    });
  };

  const toggleChangePasswordModal = () => {
    setIsChangePasswordModalOpen((pre) => !pre);
  };

  const unformatProfileData = (data) => {
    const unformatedData = {
      ...data,
      ph_no: data?.ph_no.replace(/\D/g, '')
    };

    return unformatedData;
  };

  const handleUpdateUser = async () => {
    try {
      dispatch(setLoader(true));
      const updateUserResp = await updateUser({
        user: unformatProfileData(userProfileData)
      });

      if (updateUserResp?.status === 200) {
        await loadUserDetails();
        notification('success', 'User details updated successfully');
      }
    } catch (error) {
      notification(
        'error',
        error?.response?.data?.error ||
          'An error occurred, Unable to update user details. Please try again later.'
      );
    } finally {
      dispatch(setLoader(false));
    }
  };

  useState(() => {
    setUserProfileData({
      ...userDetails?.user,
      ph_no: formatPhoneNumber(userDetails?.user?.ph_no || '')
    });
  }, []);

  return (
    <>
      <div className='flex flex-col h-full pt-6'>
        <div className='px-5 mb-4'>
          <span className='text-xl font-semibold'>Account settings</span>
        </div>

        <div
          className={`flex flex-col gap-y-6 h-full px-5 overflow-y-auto ${scrollbarStyle.customScroll}`}
        >
          <div className='flex flex-row gap-x-4 lg:w-[60%]'>
            <div className='flex flex-col w-full'>
              <span className='text-neutral-500 text-sm pb-1'>First name</span>
              <TextInput
                name='first_name'
                value={userProfileData?.first_name}
                handleChange={handleInputChange}
                inputStyle={'bg-neutral-50'}
                placeholder='First name'
                maxLength={80}
              />
            </div>
            <div className='flex flex-col w-full'>
              <span className='text-neutral-500 text-sm pb-1'>Last name</span>
              <TextInput
                name='last_name'
                value={userProfileData?.last_name}
                handleChange={handleInputChange}
                inputStyle={'bg-neutral-50'}
                placeholder='Last name'
                maxLength={80}
              />
            </div>
          </div>

          <div className='flex flex-row gap-x-4 lg:w-[60%]'>
            <div className='flex flex-col w-full'>
              <span className='text-neutral-500 text-sm pb-1'>
                Phone number
              </span>
              <TextInput
                name='ph_no'
                value={userProfileData?.ph_no}
                handleChange={handleInputChange}
                inputStyle={'bg-neutral-50'}
                placeholder='Phone number'
              />
            </div>
          </div>

          {/* <div className='flex flex-row gap-x-4 lg:w-[60%]'>
            <div className='flex flex-col w-full'>
              <span className='text-sm pb-1'>Email</span>
              <TextInput
                name='email'
                value={userProfileData?.email}
                // handleChange={handleInputChange}
                inputStyle={'bg-neutral-200'}
                placeholder='Email'
                disabled={true}
              />
            </div>
          </div> */}

          <div className='flex flex-row gap-x-4 lg:w-[60%]'>
            <div className='flex flex-col w-full'>
              <span className='text-sm pb-1'>Role at Tower Title</span>
              <TextInput
                name='role'
                value={userProfileData?.role?.label}
                // handleChange={handleInputChange}
                inputStyle={'bg-neutral-200'}
                placeholder='Role at Tower Title'
                disabled={true}
              />
            </div>
          </div>

          <div className='flex flex-row gap-x-4 lg:w-[60%] pb-1 -mt-1'>
            <div className='flex flex-col w-full'>
              <span className='text-neutral-500 text-sm pb-1'>Password</span>
              <button
                onClick={toggleChangePasswordModal}
                className='border border-black rounded-md w-fit px-2 py-1 text-sm font-medium'
              >
                Change password
              </button>
            </div>
          </div>
        </div>
        <div
          className={`flex flex-row gap-x-4 border-t border-neutral-200 px-5 py-4`}
        >
          <button
            onClick={handleUpdateUser}
            className='bg-primary-500 text-white text-sm font-medium px-3 py-2 rounded-md'
          >
            Save
          </button>

          <button
            onClick={toggleModal}
            className='bg-neutral-200 text-black text-sm font-medium px-3 py-2 rounded-md'
          >
            Cancel
          </button>
        </div>
      </div>

      <ChangePassword
        isOpen={isChangePasswordModalOpen}
        toggleModal={toggleChangePasswordModal}
      />
    </>
  );
};

export default AccountSettings;
