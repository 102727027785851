import React, { useRef, useContext, createContext } from 'react';
import SideNavBar from './sideNavBar';
import TopNavBar from './topNavBar';
import scrollbarStyle from '../../scrollbar.module.css';

const WrapperContext = createContext();

const Wrapper = (props) => {
  const { children } = props;
  const containerRef = useRef(null);
  // Scroll to this position
  const scrollToPosition = (positionX, positionY) => {
    containerRef.current.scrollTo(positionX, positionY);
  };

  // Scroll This many pixels diffrence from current position
  const scrollByPosition = (positionX, positionY) => {
    containerRef.current.scrollBy(positionX, positionY);
  };

  return (
    <WrapperContext.Provider
      value={{
        scrollToPosition,
        scrollByPosition
      }}
    >
      <div className='flex flex-row h-[100vh]'>
        <SideNavBar />

        <div className='w-[100%] sm:w-[calc(100%-222px)]'>
          {/* <TopNavBar /> */}
          <div
            ref={containerRef}
            // With topbar h-[calc(100vh-48px)]
            className={`h-[100%]  overflow-auto ${scrollbarStyle?.customScroll}`}
          >
            {children}
          </div>
        </div>
      </div>
    </WrapperContext.Provider>
  );
};

export const useWrapperContext = () => {
  return useContext(WrapperContext);
};

export default Wrapper;
