import React from 'react';
import searchIcon from '../../../assets/search.svg';
const SearchInput = ({
  name = '',
  outterStyle = '',
  inputStyle = '',
  placeholder = '',
  value,
  handlechangesearch
}) => {
  return (
    <div
      className={`flex flex-row rounded-lg bg-secondary-50 p-2 overflow-hidden ${outterStyle}`}
    >
      <img src={searchIcon} />

      <input
        name={name}
        placeholder={placeholder}
        onChange={handlechangesearch}
        value={value}
        className={`outline-none bg-transparent ml-2 text-secondary-600 ${inputStyle} text-sm w-full`}
      />
    </div>
  );
};

export default SearchInput;
