import React from 'react';
const GoAroundLoader = (props) => {
  const { height, width, color } = props;
  return (
    <svg
      version='1.1'
      id='L4'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 100 100'
      width={height}
      height={width}
    >
      <circle fill={color} stroke='none' cx='6' cy='50' r='6'>
        <animate
          attributeName='opacity'
          dur='1s'
          values='0;1;0'
          repeatCount='indefinite'
          begin='0.1'
        />
      </circle>
      <circle fill={color} stroke='none' cx='26' cy='50' r='6'>
        <animate
          attributeName='opacity'
          dur='1s'
          values='0;1;0'
          repeatCount='indefinite'
          begin='0.2'
        />
      </circle>
      <circle fill={color} stroke='none' cx='46' cy='50' r='6'>
        <animate
          attributeName='opacity'
          dur='1s'
          values='0;1;0'
          repeatCount='indefinite'
          begin='0.3'
        />
      </circle>
    </svg>
  );
};

export default GoAroundLoader;
