import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedFilters: [],
  selectedSort: 1,
  selectedTaskFilter: [],
  selectedClient: [],
  selctedTeam: [],
  selectedPriority: [],
  selectedDate: [],
  selectedProduct: [],
  selectedAssignee: []
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setSelectedFilters: (state, action) => {
      state.selectedFilters = action?.payload;
    },
    setSelectedSort: (state, action) => {
      state.selectedSort = action?.payload;
    },
    setSelectedTaskFilter: (state, action) => {
      state.selectedTaskFilter = action?.payload;
    },
    setSelectedClient: (state, action) => {
      state.selectedClient = action?.payload;
    },
    setSelectedTeam: (state, action) => {
      state.selctedTeam = action?.payload;
    },
    setSelectedPriority: (state, action) => {
      state.selectedPriority = action?.payload;
    },
    setSelectedDate: (state, action) => {
      state.selectedDate = action?.payload;
    },
    setSelectedProduct: (state, action) => {
      state.selectedProduct = action?.payload;
    },
    setSelectedAssignee: (state, action) => {
      state.selectedAssignee = action?.payload;
    },
    resetToInitialState: (state) => {
      state.selectedFilters = [];
      state.selectedSort = 1;
      state.selectedTaskFilter = [];
      state.selectedClient = [];
      state.selctedTeam = [];
      state.selectedPriority = [];
      state.selectedDate = [];
      state.selectedProduct = [];
      state.selectedAssignee = [];
    }
  }
});

export const {
  setSelectedFilters,
  setSelectedSort,
  setSelectedTaskFilter,
  setSelectedClient,
  setSelectedTeam,
  setSelectedPriority,
  setSelectedDate,
  setSelectedProduct,
  setSelectedAssignee,
  resetToInitialState
} = dashboardSlice?.actions;

export default dashboardSlice?.reducer;
