import React from 'react';
import { Popover } from 'react-tiny-popover';
const ConfirmationPopOver = ({
  children,
  showPopOver,
  handleClickPopOver,
  title,
  message,
  onConfirm
}) => {
  return (
    <Popover
      isOpen={showPopOver}
      align={'end'}
      reposition={false}
      positions={['bottom']}
      onClickOutside={() => {
        handleClickPopOver();
      }}
      content={() => {
        return (
          <div
            onClick={(e) => e.stopPropagation()}
            className='flex flex-col bg-white px-3 py-2 w-[350px] rounded border border-neutral-200 shadow-md'
          >
            <span className='text-lg font-medium'>{title}</span>
            <p className='text-sm text-black'>{message}</p>

            <div className='flex flex-row justify-end gap-x-2 mt-2'>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleClickPopOver();
                }}
                className='px-2 py-1 text-black bg-[#f7f7f7] rounded-md text-sm'
              >
                Cancel
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleClickPopOver();
                  onConfirm();
                }}
                className='px-2 py-1 bg-primary-500 text-white rounded-md text-sm'
              >
                Confirm
              </button>
            </div>
          </div>
        );
      }}
      containerStyle={{
        zIndex: 20
      }}
    >
      {children}
    </Popover>
  );
};

export default ConfirmationPopOver;
