import React, { useState } from 'react';
const TextInput = ({
  inputRef = undefined,
  name = '',
  outterStyle = '',
  inputStyle = '',
  placeholder = '',
  value,
  handleChange,
  icon,
  iconStyle,
  type,
  maxLength = '5000',
  disabled = false,
  error,
  errorStyle,
  message,
  messageStyle,
  isOptional,
  istextArea = false,
  textAreaRow = 1,
  onFocus,
  onBlur,
  handleKeyDown = () => {},
  tabIndex
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const InputComponent = istextArea ? 'textarea' : 'input';

  return (
    <>
      <div className={`flex flex-row ${outterStyle} items-center`}>
        {icon && <img src={icon} className={`${iconStyle} mr-1.5`} />}

        <InputComponent
          tabIndex={tabIndex}
          ref={inputRef}
          name={name}
          className={`text-neutral-800 outline-none p-2.5 text-sm  border border-transparent disabled:bg-white focus:border-neutral-200 hover:bg-neutral-100 rounded-lg w-full placeholder-black/50 ${inputStyle}`}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          type={type}
          onInput={(e) => {
            if (e.currentTarget.value.length >= maxLength) {
              e.currentTarget.value = e.currentTarget.value.slice(0, maxLength);
            }
          }}
          disabled={disabled}
          onFocus={() => {
            setIsFocused(true);
            onFocus && onFocus();
          }}
          onBlur={() => {
            setIsFocused(false);
            onBlur && onBlur();
          }}
          rows={textAreaRow}
          onKeyDown={handleKeyDown}
        />

        {isFocused && isOptional && (
          <span className='text-xs font-medium text-neutral-500 right-2 ml-2.5'>
            Optional
          </span>
        )}
      </div>

      {message && (
        <p className={`text-xs text-neutral-800 ${messageStyle}`}>{message}</p>
      )}

      {error && (
        <p className={`text-xs text-error-500 ${errorStyle}`}>{error}</p>
      )}
    </>
  );
};

export default TextInput;
