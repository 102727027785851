import React, { useEffect, useState } from 'react';
import { useAuth } from '../../auth';
import { useSelector } from 'react-redux';
import { setTopBarHeading } from '../../../redux/slices/topBarHeading';
import logo from '../../../assets/fullLogo.svg';
import dashboardIcon from '../../../assets/dasboard.svg';
import profileDashboardIcon from '../../../assets/ProfileDashboard.svg';
import inboxIcon from '../../../assets/inbox.svg';
import noteIcon from '../../../assets/note.svg';
import profileIcon from '../../../assets/profile.svg';
import SquareUpArrow from '../../../assets/squareUpArrow.svg';
import GearIcon from '../../../assets/GearIcon.svg';
import LogOutIcon from '../../../assets/LogOutIcon.svg';
import OrderTab from './components/OrderTab';

import { Popover } from 'react-tiny-popover';
import UserAccount from '../../../pages/UserAccount';
import DashBoardTab from './components/DashBoardTab';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../constants/routes';
import ProfileTab from './components/ProfileTab';

const SideNavBar = () => {
  const navigation = useNavigate();
  const { handleLogout } = useAuth();
  const { userDetails } = useSelector((state) => state?.userDetails);
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const [userDetailsModalOpen, setUserDetailsModalOpen] = useState(false);
  const [openTabName, setOpenTabName] = useState('orders');
  const [isProfilePopOverOpen, setIsProfilePopOverOpen] = useState(false);

  const sideTabList = [
    {
      icon: dashboardIcon,
      name: 'dashboard'
    },
    {
      icon: noteIcon,
      name: 'orders'
    },
    {
      icon: profileDashboardIcon,
      name: 'profile'
    },
    {
      icon: inboxIcon,
      name: 'inbox'
    }
  ];

  const toggleIsSideBarOpen = () => {
    setIsSideBarOpen((pre) => !pre);
  };

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  function debounce(fn, ms) {
    let timer;
    return (_) => {
      clearTimeout(timer);
      timer = setTimeout((_) => {
        timer = null;
        fn();
      }, ms);
    };
  }

  const handleChangeTab = (name) => {
    setOpenTabName(name);
  };

  const renderSideBarTab = () => {
    switch (openTabName) {
      case 'dashboard':
        return <DashBoardTab />;
      case 'orders':
        return <OrderTab />;
      case 'profile':
        return <ProfileTab />;
      case 'inbox':
        return <div className='text-white'>Work in progress</div>;
    }
  };

  const toggleProfilePopOver = () => {
    setIsProfilePopOverOpen((pre) => !pre);
  };

  const toggleUserDetailsModal = () => {
    setUserDetailsModalOpen((pre) => !pre);
  };

  const handleClickLogout = async () => {
    toggleIsSideBarOpen();
    handleLogout();
  };

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
    }, 50);

    window.addEventListener('resize', debouncedHandleResize);

    return (_) => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, [dimensions]);

  return (
    <>
      {dimensions.width < 640 && !isSideBarOpen ? (
        <div className='flex absolute w-14 h-12 bg-gray-200  rounded justify-center items-center opacity-80'>
          <button onClick={toggleIsSideBarOpen}>
            <img src={SquareUpArrow} className='rotate-90' />
          </button>
        </div>
      ) : (
        <div
          className={`absolute sm:relative bg-secondary-500 w-[60%] sm:w-[222px] flex flex-col h-full z-[15]`}
        >
          {/* Close In Case of Small device */}
          <div className='absolute sm:hidden right-[-2px] top-[15px] rounded p-1 py-0 shadow-md'>
            <button onClick={toggleIsSideBarOpen}>
              <img src={SquareUpArrow} className='-rotate-90' />
            </button>
          </div>

          <div
            onClick={() => {
              navigation(ROUTES.dashbaord);
            }}
            className='flex justify-center mt-6 cursor-pointer'
          >
            <img src={logo} />
          </div>

          <div className='mt-6'>
            <SideNavBarTab
              sideTabList={sideTabList}
              openTabName={openTabName}
              handleChangeTab={handleChangeTab}
            />
          </div>

          <div className='mx-2 mt-5 flex-1 overflow-hidden'>
            {renderSideBarTab()}
          </div>

          {/* User Profile Icon */}

          <Popover
            isOpen={isProfilePopOverOpen}
            align={'start'}
            onClickOutside={() => {
              toggleProfilePopOver();
            }}
            reposition={false}
            positions={['top']}
            content={
              <div className='flex flex-col w-[200px] bg-white divide-y divide-neutral-200 rounded-lg overflow-hidden'>
                <button
                  onClick={() => {
                    toggleProfilePopOver();
                    toggleUserDetailsModal();
                  }}
                  className='flex flex-row p-2 hover:bg-neutral-200 gap-x-3 items-center'
                >
                  <img src={GearIcon} className='w-6 h-6' />
                  <span className='text-sm mb-0.5'>Account</span>
                </button>

                <button
                  onClick={handleClickLogout}
                  className='flex flex-row p-2 hover:bg-neutral-200 gap-x-3 items-center'
                >
                  <img src={LogOutIcon} className='w-6 h-6' />
                  <span className='text-sm mb-0.5'>Sign out</span>
                </button>
              </div>
            }
            containerStyle={{
              zIndex: 15,
              paddingBottom: '11px'
            }}
          >
            <button
              onClick={toggleProfilePopOver}
              className='flex flex-row mt-1 mb-6 mx-3 items-center'
            >
              <img src={profileIcon} />
              <span className='px-2 text-white text-base'>
                {userDetails?.user?.first_name +
                  ' ' +
                  userDetails?.user?.last_name}
              </span>
            </button>
          </Popover>
        </div>
      )}

      {userDetailsModalOpen && (
        <UserAccount
          isOpen={userDetailsModalOpen}
          toggleModal={toggleUserDetailsModal}
        />
      )}
    </>
  );
};

const SideNavBarTab = ({ sideTabList, openTabName, handleChangeTab }) => {
  return (
    <div className='flex justify-between px-4'>
      {sideTabList.map((sideBardItem, index) => (
        <div
          key={index}
          className={`${
            openTabName === sideBardItem?.name
              ? 'bg-gray-100'
              : 'bg-secondary-400'
          } p-1.5 rounded-md cursor-pointer`}
          onClick={() => {
            handleChangeTab(sideBardItem?.name);
          }}
        >
          <img src={sideBardItem?.icon} />
        </div>
      ))}
    </div>
  );
};

export default SideNavBar;
