import React, { useEffect, useState } from 'react';
import Button from '../../../button';
import SearchInput from '../../../input/SearchInput';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../../constants/routes';
import scrollbarStyle from '../../../../scrollbar.module.css';
import { fetchOrders, setOrdersList } from '../../../../redux/slices/order';

import plusIcon from '../../../../assets/plus.svg';
import threeDotsIcon from '../../../../assets/threeDots.svg';
import downArrowIcon from '../../../../assets/downArrow.svg';
import noteIcon from '../../../../assets/note.svg';
import Cube from '../../../../assets/CubeWhite.svg';

import { useDispatch, useSelector } from 'react-redux';
import { Popover } from 'antd';
import dayjs from 'dayjs';

const dummyRecentOrders = [
  {
    id: 1,
    uniqueId: 'UNQ-001',
    lastVisited: '2024-04-15T05:00:00.000Z',
    clientOrderNumber: 'ORD-001',
    propertyAddress: '123 Main St Ave, New York, NY 10001, Kings County',
    projectName: 'Project 1'
  },
  {
    id: 2,
    uniqueId: 'UNQ-002',
    lastVisited: '2024-04-15T05:00:00.000Z',
    clientOrderNumber: 'ORD-002',
    propertyAddress: '123 Main St Ave, New York, NY 10001, Kings County',
    projectName: 'Project 2'
  },
  {
    id: 3,
    uniqueId: 'UNQ-003',
    lastVisited: '2024-04-15T05:00:00.000Z',
    clientOrderNumber: 'ORD-003',
    propertyAddress: '123 Main St Ave, New York, NY 10001, Kings County',
    projectName: 'Project 3'
  },
  {
    id: 4,
    uniqueId: 'UNQ-004',
    lastVisited: '2024-04-15T05:00:00.000Z',
    clientOrderNumber: 'ORD-001',
    propertyAddress: '123 Main St Ave, New York, NY 10001, Kings County',
    projectName: 'Project 4'
  },
  {
    id: 5,
    uniqueId: 'UNQ-005',
    lastVisited: '2024-04-15T05:00:00.000Z',
    clientOrderNumber: 'ORD-002',
    propertyAddress: '123 Main St Ave, New York, NY 10001, Kings County',
    projectName: 'Project 5'
  },
  {
    id: 6,
    uniqueId: 'UNQ-006',
    lastVisited: '2024-04-15T05:00:00.000Z',
    clientOrderNumber: 'ORD-003',
    propertyAddress: '123 Main St Ave, New York, NY 10001, Kings County',
    projectName: 'Project 6'
  },
  {
    id: 7,
    uniqueId: 'UNQ-007',
    lastVisited: '2024-04-15T05:00:00.000Z',
    clientOrderNumber: 'ORD-001',
    propertyAddress: '123 Main St Ave, New York, NY 10001, Kings County',
    projectName: 'Project 7'
  },
  {
    id: 8,
    uniqueId: 'UNQ-008',
    lastVisited: '2024-04-15T05:00:00.000Z',
    clientOrderNumber: 'ORD-002',
    propertyAddress: '123 Main St Ave, New York, NY 10001, Kings County',
    projectName: 'Project 8'
  },
  {
    id: 9,
    uniqueId: 'UNQ-009',
    lastVisited: '2024-04-15T05:00:00.000Z',
    clientOrderNumber: 'ORD-003',
    propertyAddress: '123 Main St Ave, New York, NY 10001, Kings County',
    projectName: 'Project 9'
  },
  {
    id: 10,
    uniqueId: 'UNQ-0010',
    lastVisited: '2024-04-15T05:00:00.000Z',
    clientOrderNumber: 'ORD-003',
    propertyAddress: '123 Main St Ave, New York, NY 10001, Kings County',
    projectName: 'Project 10'
  },
  {
    id: 11,
    uniqueId: 'UNQ-0011',
    lastVisited: '2024-04-15T05:00:00.000Z',
    clientOrderNumber: 'ORD-003',
    propertyAddress: '123 Main St Ave, New York, NY 10001, Kings County',
    projectName: 'Project 11'
  },
  {
    id: 12,
    uniqueId: 'UNQ-0012',
    lastVisited: '2024-04-15T05:00:00.000Z',
    clientOrderNumber: 'ORD-003',
    propertyAddress: '123 Main St Ave, New York, NY 10001, Kings County',
    projectName: 'Project 11'
  }
];

const OrderTab = () => {
  // Sample DATA
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const { ordersList } = useSelector((state) => state?.order);

  const [searchText, setSearchText] = useState('');

  const currentUrl = window?.location?.href;
  const routeName = currentUrl?.split('/')?.slice(-2, -1)?.[0];
  const params = currentUrl?.split('/')?.pop();

  const handleChangeSerachText = (e) => {
    setSearchText(e.target.value);
  };

  const handleSelectOrder = (selectedOrderId) => {
    navigation(`${ROUTES.orderDetails}/activity/${selectedOrderId}`);
  };

  const performSearch = async (searchInput) => {
    dispatch(setOrdersList([]));

    if (searchText) {
      console.log('SEARCH ', searchInput);
    } else {
      dispatch(fetchOrders());
    }
  };

  // throttle
  useEffect(() => {
    const throttleTimeout = setTimeout(() => {
      if (searchText !== null) {
        performSearch(searchText);
      }
    }, 1000);

    return () => {
      clearTimeout(throttleTimeout);
    };
  }, [searchText]);

  return (
    <div className='flex flex-col h-full'>
      <div className='mb-5'>
        <SearchInput
          placeholder={'Search'}
          handlechangesearch={handleChangeSerachText}
          value={searchText}
        />
      </div>

      <div className='flex flex-col gap-y-2 border-b border-neutral-600 pb-2.5'>
        <Button
          icon={plusIcon}
          title={'New Order'}
          iconStyle={'mr-2'}
          textStyle={`text-sm`}
          buttonStyle={'rounded-lg'}
          handleClick={() => {
            navigation(ROUTES.createOrder);
          }}
        />

        <Button
          icon={Cube}
          title={'View all Orders'}
          iconStyle={'mr-2'}
          textStyle={`text-sm`}
          buttonStyle={'rounded-lg'}
          handleClick={() => {
            navigation(ROUTES.allOrders);
          }}
        />
      </div>

      <div className='flex flex-col gap-y-1 py-2.5 h-full overflow-hidden'>
        <span className='text-white font-semibold'>Recent Views</span>

        <div className='flex flex-col h-full gap-y-0.5'>
          {dummyRecentOrders?.map((orderItem, index) => (
            <OrderItem
              key={index}
              orderItem={orderItem}
              handleSelectOrder={handleSelectOrder}
              routeName={routeName}
              params={params}
            />
          ))}
        </div>

        {/* <div className={`h-full overflow-auto ${scrollbarStyle?.customScroll}`}>
          {ordersList.map((orderItem, index) => (
            <div key={index} className='my-3'>
              <CategoryWiseOrder
                orderItem={orderItem}
                handleSelectOrder={handleSelectOrder}
                routeName={routeName}
                params={params}
              />
            </div>
          ))}
        </div> */}
      </div>
    </div>
  );
};

const OrderItem = ({ orderItem, handleSelectOrder, routeName, params }) => {
  return (
    <Popover
      placement='bottomLeft'
      overlayInnerStyle={{
        padding: 0
      }}
      overlayStyle={{
        borderRadius: 4
      }}
      content={
        <div className='flex flex-col gap-y-1.5 max-w-[200px] overflow-hidden p-2'>
          <div className='flex flex-col'>
            <span className='text-xs text-neutral-600'>Last Visited</span>
            <span className='text-sm text-neutral-800'>
              {dayjs(orderItem?.lastVisited)?.format('DD MMM YYYY hh:mm A')}
            </span>
          </div>

          <div className='flex flex-col'>
            <span className='text-xs text-neutral-600'>
              Client’s Order Number
            </span>
            <span className='text-sm text-neutral-800'>
              {orderItem?.clientOrderNumber}
            </span>
          </div>

          <div className='flex flex-col'>
            <span className='text-xs text-neutral-600'>Property Address</span>
            <span className='text-sm text-neutral-800'>
              {orderItem?.propertyAddress}
            </span>
          </div>

          <div className='flex flex-col'>
            <span className='text-xs text-neutral-600'>Project Name</span>
            <span className='text-sm text-neutral-800'>
              {orderItem?.projectName}
            </span>
          </div>
        </div>
      }
    >
      <div
        key={orderItem?.id}
        className={`my-0.5 px-1 py-1.5 hover:bg-primary-50/5 ${
          routeName === 'orderDetails' && Number(params) === orderItem?.id
            ? 'bg-primary-50/5'
            : ''
        }`}
        onClick={() => handleSelectOrder(orderItem?.id)}
      >
        <div className='flex flex-row items-center cursor-pointer '>
          <img src={noteIcon} className='w-[16px] h-[20px]' />
          <span className='ml-2 text-white text-sm select-none'>
            {orderItem?.uniqueId}
          </span>
        </div>
      </div>
    </Popover>
  );
};

// const CategoryWiseOrder = ({
//   orderItem,
//   handleSelectOrder,
//   routeName,
//   params
// }) => {
//   const [islistOpen, setIsListOpen] = useState(false);

//   const toggleListOpen = () => {
//     setIsListOpen((pre) => !pre);
//   };

//   return (
//     // hover:bg-primary-50/5
//     <div className=' rounded'>
//       <div className='flex flex-row justify-between items-center px-2 py-1.5'>
//         <div
//           onClick={toggleListOpen}
//           className='flex flex-row justify-center cursor-pointer'
//         >
//           <img
//             src={downArrowIcon}
//             className={`p-1 ${islistOpen ? '' : '-rotate-90'}`}
//           />

//           <span className='pl-2 text-white text-sm select-none '>
//             {orderItem?.label}
//           </span>
//         </div>
//         {/* <img src={threeDotsIcon} className='cursor-pointer' /> */}
//       </div>

//       {islistOpen && (
//         <div>
//           {orderItem?.orderList.map((orderDetails) => {
//             return (
//               <div
//                 key={orderDetails?.id}
//                 className={`my-0.5 mx-1 px-2 py-1.5 hover:bg-primary-50/5 ${
//                   routeName === 'orderDetails' &&
//                   Number(params) === orderDetails?.id
//                     ? 'bg-primary-50/5'
//                     : ''
//                 }`}
//                 onClick={() => handleSelectOrder(orderDetails?.id)}
//               >
//                 <div className='flex flex-row items-center cursor-pointer '>
//                   <img src={noteIcon} className='w-[16px] h-[20px]' />
//                   <span className='ml-2 text-white text-sm select-none'>
//                     {orderDetails?.uniqueId}
//                   </span>
//                 </div>
//               </div>
//             );
//           })}
//         </div>
//       )}
//     </div>
//   );
// };

export default OrderTab;
