const ROUTES = {
  dashbaord: '/',
  createOrder: '/createOrder',
  orderDetails: '/orderDetails',
  login: '/login',
  resetPassword: '/resetPassword',
  setPassword: '/setPassword',
  onboarding: '/onboarding',
  newProfile: '/newProfile',
  profile: '/profile',
  allOrders: '/allOrders'
};

export default ROUTES;
